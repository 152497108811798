<template>
  <div class="inner root" :style="{'background-image': `url(${require('@/assets/edu/342x300.png')})`}">
    <div class="title">从业人员健康证</div>
    <div ref="health" class="charts" style="margin-top: -20px" />
  </div>
</template>

<script>
import { healthInfo } from '@/api/edu/edu'
import * as echarts from 'echarts'
import resize from '@/libs/mixin/resize'
export default {
  mixins: [resize],
  data() {
    return {
      chart: '',
      healthList: []
    }
  },
  methods: {
    init(school) {
      this.chart = echarts.init(this.$refs.health)
      this.healthList = []
      healthInfo(school).then(res => {
        this.healthList.push({ name: '无健康证', value: res.data.empNoHealthCard })
        this.healthList.push({ name: '正常', value: res.data.empCount - (res.data.empNoHealthCard || 0) - (res.data.empHealthCardNoonExpire || 0) - (res.data.empHealthCardExpire || 0) })
        this.healthList.push({ name: '即将过期', value: res.data.empHealthCardNoonExpire })
        this.healthList.push({ name: '已过期', value: res.data.empHealthCardExpire })
        this.setOptions()
      })
    },
    setOptions() {
      this.chart.setOption({
        darkMode: true,
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '健康证',
            type: 'pie',
            radius: ['40%', '60%'],
            data: this.healthList
          }
        ]
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import './BaseStyle.scss';
</style>
