<template>
  <div class="inner root" :style="{'background-image': `url(${require('@/assets/edu/650x280.png')})`}">
    <div class="header">
      <div class="title">晨检情况数据分析</div>
      <div class="ctr">
        <div class="btn" :class="{'current': type === 'week'}" @click="switchType('week')">本周</div>
        <div class="btn" :class="{'current': type === 'month'}" @click="switchType('month')">本月</div>
        <div class="btn" :class="{'current': type === 'year'}" @click="switchType('year')">本年</div>
      </div>
    </div>
    <div ref="check" class="charts" />
  </div>
</template>

<script>
import { morningCheck } from '@/api/edu/edu'
import * as echarts from 'echarts'
import resize from '@/libs/mixin/resize'
export default {
  mixins: [resize],
  data() {
    return {
      chart: '',
      // week / year / month
      type: 'week',
      school: '',
      category: [],
      empCount: [],
      check: [],
      unCheck: []
    }
  },
  methods: {
    init(school) {
      this.school = school
      this.chart = echarts.init(this.$refs.check)
      this.query()
    },
    query() {
      morningCheck(this.type, this.school).then(res => {
        if (res.data) {
          this.category = res.data.map(e => e.monthName)
          this.empCount = res.data.map(e => e.empCount)
          this.check = res.data.map(e => e.checkCount)
          this.unCheck = res.data.map(e => e.nocheckCount)
        }
        this.setOptions()
      })
    },
    setOptions() {
      this.chart.setOption({
        darkMode: true,
        legend: {
          textStyle: {
            color: '#fff'
          },
          top: -5
        },
        textStyle: {
          color: '#fff'
        },
        grid: {
          bottom: '105',
          right: '25',
          left: '45',
          top: '25'
        },
        tooltip: {
          trigger: 'axis'
        },
        dataZoom: [
          {
            type: 'inside'
          },
          {
            type: 'slider',
            bottom: 60,
            height: 20
          }
        ],
        xAxis: [
          {
            type: 'category',
            data: this.category,
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '1',
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '签到',
            type: 'bar',
            data: this.empCount
          },
          {
            name: '晨检',
            type: 'bar',
            data: this.check
          },
          {
            name: '未晨检',
            type: 'line',
            data: this.unCheck
          }
        ]
      })
    },
    switchType(type) {
      this.type = type
      this.query()
    }
  }
}
</script>

<style scoped lang="scss">
@import './BaseStyle.scss';
.header {
  display: flex;
  justify-content: space-between;
}
.ctr {
  display: flex;
  padding-top: 25px;
  padding-right: 25px;
  font-size: 14px;
  font-weight: 100;
}
.btn {
  width: 40px;
  cursor: pointer;
  text-align: center;
  height: 25px;
  line-height: 25px;
  border-radius: 5%;
}
.current {
  background-color: #0e5dfb;
}
</style>
