import request from '@/axios'

const baseUrl = 'edu'

/**
 * 学校分类统计
 * @returns
 */
export const schoolType = () => {
  return request({
    url: baseUrl + '/school-type',
    method: 'get'
  })
}

/**
 * 从业人员健康证
 * @param {*} school
 * @returns
 */
export const healthInfo = (school) => {
  return request({
    url: baseUrl + '/health-info',
    method: 'get',
    params: {
      schoolId: school
    }
  })
}

/**
 * 物联设备状态
 * @param {*} school
 * @returns
 */
export const iotState = (schoolId) => {
  return request({
    url: baseUrl + '/iot-state',
    method: 'get',
    params: {
      schoolId
    }
  })
}

/**
 * 晨检情况数据分析
 * @param {*} type
 * @param {*} schoolId
 */
export const morningCheck = (type, schoolId) => {
  return request({
    url: baseUrl + '/morning-check',
    method: 'get',
    params: {
      type,
      schoolId
    }
  })
}

/**
 * 食材采购分析
 * @param {*} type
 * @param {*} schoolId
 * @returns
 */
export const ingredientsPurchase = (type, schoolId) => {
  return request({
    url: baseUrl + '/ingredients-purchase',
    method: 'get',
    params: {
      type,
      schoolId
    }
  })
}

/**
 * 预警信息统计
 * @param {*} type
 * @param {*} schoolId
 * @returns
 */
export const warningAnalyze = (type, schoolId) => {
  return request({
    url: baseUrl + '/warning-analyze',
    method: 'get',
    params: {
      type,
      schoolId
    }
  })
}

/**
 * 即时预警信息
 * @param {*} schoolId
 */
export const warningInfo = (schoolId) => {
  return request({
    url: baseUrl + '/warning-info',
    method: 'get',
    params: {
      schoolId
    }
  })
}
