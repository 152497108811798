<template>
  <div class="e-root" :style="{'background-image': `url(${require('@/assets/edu/background.png')})`}">
    <div class="e-top-banner">
      <div class="e-school-name" :style="{'background-image': `url(${require('@/assets/common/top-left.png')})`}">{{ schoolName }}</div>
      <div class="e-title" :style="{'background-image': `url(${require('@/assets/common/top-center.png')})`}">互联网+明厨亮灶监管平台 信息公示看板</div>
      <div class="e-time" :style="{'background-image': `url(${require('@/assets/common/top-right.png')})`}">{{ now + " " + week }}</div>
    </div>
    <div class="e-content">
      <el-row :gutter="20">
        <el-col :span="18">
          <el-row :gutter="20" class="e-row">
            <el-col :span="7" class="e-col">
              <SchoolType ref="schoolType" />
            </el-col>
            <el-col :span="7" class="e-col">
              <HealthCard ref="healthCard" />
            </el-col>
            <el-col :span="10" class="e-col">
              <IotState ref="iotState" />
            </el-col>
          </el-row>
          <el-row :gutter="20" class="e-row">
            <el-col :span="12" class="e-col">
              <MorningCheck ref="morningCheck" />
            </el-col>
            <el-col :span="12" class="e-col">
              <IngrdientsPurchase ref="ingrdientsPurchase" />
            </el-col>
          </el-row>
          <el-row :gutter="20" class="e-row">
            <el-col :span="24" class="e-col">
              <WarningAnalyze ref="warningAnalyze" />
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6" class="e-col-1">
          <WarningList ref="warningList" />
        </el-col>
      </el-row>
    </div>
    <div class="toolbar" :style="{'background-image': `url(${require('@/assets/common/14x300.png')})`}" @click="toggle" />
    <SelectPanel v-if="!school" ref="selectPanel" @reload="reload" />
    <Menu ref="menu" current="dashboard" @evt="menuEvt" />
  </div>
</template>

<script>
import { schoolDetail } from '@/api/school/school'
import SchoolType from './component/SchoolType.vue'
import HealthCard from './component/HealthCard.vue'
import IotState from './component/IotState.vue'
import MorningCheck from './component/MorningCheck.vue'
import IngrdientsPurchase from './component/IngrdientsPurchase.vue'
import WarningAnalyze from './component/WarningAnalyze.vue'
import WarningList from './component/WarningList.vue'
import SelectPanel from './component/SelectPanel.vue'
import Menu from './component/Menu.vue'
export default {
  components: {
    SchoolType,
    HealthCard,
    IotState,
    MorningCheck,
    IngrdientsPurchase,
    WarningAnalyze,
    WarningList,
    SelectPanel,
    Menu
  },
  data() {
    return {
      now: '',
      week: '',
      show: false,
      school: '',
      schoolName: ''
    }
  },
  mounted() {
    const date = new Date()
    this.now = date.getFullYear() + '.' + (date.getMonth() + 1) + '.' + date.getDate()
    this.week = '星期' + '日一二三四五六'.charAt(new Date().getDay())
    this.init()
  },
  methods: {
    init() {
      if (this.school) {
        schoolDetail(this.school).then(res => {
          this.schoolName = res.data.companyName
        })
      }

      this.refresh60Min()
      this.refresh5Min()

      // 以下60分钟刷新
      setInterval(this.refresh60Min, 60 * 60 * 1000)
      // 以下5分钟刷新
      setInterval(this.refresh5Min, 5 * 60 * 1000)
    },
    toggle() {
      this.show = !this.show
      if (!this.school) {
        this.$refs.selectPanel.toggle(this.show)
      } else {
        this.$refs.menu.toggle(this.show)
      }
    },
    reload(school) {
      this.school = school
      this.show = false
      this.refrashNow()
    },
    menuEvt(evtName) {
      this.toggle()
      if (evtName === 'today') {
        this.$router.push({ path: '/school-dashboard', query: { school: this.school }})
      } else if (evtName === 'video') {
        this.$router.push({ path: '/camera-video', query: { school: this.school }})
      } else if (evtName === 'back') {
        this.school = ''
        this.schoolName = ''
        this.init()
      }
    },

    refresh5Min() {
      this.$refs.warningAnalyze.init(this.school)
      this.$refs.warningList.init(this.school)
      const date = new Date()
      this.now = date.getFullYear() + '.' + (date.getMonth() + 1) + '.' + date.getDate()
      this.week = '星期' + '日一二三四五六'.charAt(new Date().getDay())
    },
    refresh60Min() {
      this.$refs.schoolType.init(this.school)
      this.$refs.healthCard.init(this.school)
      this.$refs.iotState.init(this.school)
      this.$refs.morningCheck.init(this.school)
      this.$refs.ingrdientsPurchase.init(this.school)
    },
    refrashNow() {
      if (this.school) {
        schoolDetail(this.school).then(res => {
          this.schoolName = res.data.companyName
        })
      }
      this.$refs.warningAnalyze.init(this.school)
      this.$refs.warningList.init(this.school)
      this.$refs.schoolType.init(this.school)
      this.$refs.healthCard.init(this.school)
      this.$refs.iotState.init(this.school)
      this.$refs.morningCheck.init(this.school)
      this.$refs.ingrdientsPurchase.init(this.school)
    }
  }
}
</script>

<style scoped lang="scss">
.e-root {
  height: 100vh;
  width: 100vw;
  background-size: 100% 100%;
  color: white;
  font-family: 思源黑体;
  font-weight: bold;
}
.e-top-banner {
  height: calc(10vh);
  display: flex;
  justify-content: space-evenly;
  line-height: calc(10vh);
  align-items: center;
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
  .e-school-name {
    height: 60px;
    width: 400px;
    line-height: 40px;
  }
  .e-title {
    height: 68px;
    width: 1038px;
    line-height: 68px;
    font-size: 20px;
    font-weight: bold;
  }
  .e-time {
    height: 45px;
    width: 250px;
    line-height: 45px;
  }
}
.e-content{
  padding: 0px 20px;
}
.e-row {
  height: calc(29.5vh);
}
.e-col {
  height: 100%;
}
.e-col-1 {
  height: calc(88vh);
}
.toolbar {
  height: 300px;
  width: 14px;
  position: absolute;
  top: 150px;
  right: 0px;
  cursor: pointer;
}
</style>
