<template>
  <div class="inner root" :style="{'background-image': `url(${require('@/assets/edu/342x300.png')})`}">
    <div class="title">学校分类统计</div>
    <div ref="school" class="charts" />
  </div>
</template>

<script>
import { schoolType } from '@/api/edu/edu'
import * as echarts from 'echarts'
import resize from '@/libs/mixin/resize'
export default {
  mixins: [resize],
  data() {
    return {
      chart: '',
      school: []
    }
  },
  methods: {
    init(school) {
      this.chart = echarts.init(this.$refs.school)
      this.school = []
      schoolType(school).then(res => {
        for (const item in res.data) {
          this.school.push({
            name: res.data[item].companyType,
            value: res.data[item].companyTypeCount
          })
        }
        this.setOptions()
      })
    },
    setOptions() {
      this.chart.setOption({
        darkMode: true,
        legend: {
          textStyle: {
            color: '#fff'
          },
          top: 15
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '学校',
            type: 'pie',
            radius: '50%',
            data: this.school
          }
        ]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './BaseStyle.scss';
</style>
