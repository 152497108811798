<template>
  <div class="inner root" :style="{'background-image': `url(${require('@/assets/edu/596x300.png')})`}">
    <div class="title">物联设备状态</div>
    <div ref="iot" class="charts" />
  </div>
</template>

<script>
import { iotState } from '@/api/edu/edu'
import * as echarts from 'echarts'
import resize from '@/libs/mixin/resize'
export default {
  mixins: [resize],
  data() {
    return {
      chart: '',
      labelList: [],
      onlineList: [],
      offlineList: []
    }
  },
  methods: {
    init(school) {
      this.chart = echarts.init(this.$refs.iot)
      this.labelList = []
      this.onlineList = []
      this.offlineList = []
      iotState(school).then(res => {
        this.labelList = res.data.map(e => e.checkItem)
        this.onlineList = res.data.map(e => e.onlineNumber)
        this.offlineList = res.data.map(e => e.offlineNumber)
        this.setOptions()
      })
    },
    setOptions() {
      this.chart.setOption({
        darkMode: true,
        legend: {
          textStyle: {
            color: '#fff'
          },
          top: -5
        },
        grid: {
          bottom: '70',
          right: '25',
          left: '40',
          top: 25
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: this.labelList
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          }
        },
        textStyle: {
          color: '#fff'
        },
        series: [
          {
            name: '在线',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: this.onlineList
          },
          {
            name: '离线',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: this.offlineList
          }
        ]
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import './BaseStyle.scss';
</style>
