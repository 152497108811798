<template>
  <div class="select-root" :class="show ? 'show': ''" :style="{'background-image': `url(${require('@/assets/common/select/312x280.png')})`}">
    <div class="top">
      <div class="bg" :style="{'background-image': `url(${require('@/assets/common/select/254x30.png')})`}">
        <input v-model="companyName" class="input" placeholder="请输入学校名称回车搜索" @keyup.enter="enter">
      </div>
    </div>
    <div class="content">
      <div class="school-list">
        <div v-for="school in schoolList" :key="school.id" class="row" @click="reloadData(school.id)">{{ school.companyName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { companyList } from '@/api/choose'
export default {
  data() {
    return {
      show: false,
      schoolList: [],
      companyName: ''
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    toggle(show) {
      this.show = show
    },
    reloadData(schoolId) {
      this.$emit('reload', schoolId)
    },
    enter() {
      this.search()
    },
    search() {
      companyList(this.companyName).then(res => {
        this.schoolList = res.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
.select-root {
  width: 312px;
  height: 280px;
  position: absolute;
  right: -312px;
  display: none;
  top: 155px;
  .top {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .bg {
    height: 30px;
    width: 254px;
    margin: 20px auto 0px auto;
  }
  .input {
    height: 30px;
    width: 210px;
    background-color: transparent;
    background-size: 100% 100%;
    float: right;
    border: none;
    font-size: 16px;
    color: #fff;
  }
  ::-webkit-input-placeholder {
    color: #fff;
  }
  .input:focus {
    outline: none;
  }
}
.content {
  .school-list {
    width: 90%;
    height: 190px;
    margin: 0px auto;
    overflow-x: hidden;
    overflow-y: scroll;
    .row {
      height: 24px;
      line-height: 24px;
      cursor: pointer;
    }
    .row:hover {
      background-color: #0e5dfb;
    }
  }
}
.show {
  right: 14px !important;
  display: block;
}
</style>
