<template>
  <div class="inner root" :style="{'background-image': `url(${require('@/assets/edu/476x900.png')})`}">
    <div class="title" style="padding-top: 38px;">即时预警信息</div>
    <div>
      <vue-seamless-scroll :data="dataList" :class-option="classOption" class="scroll">
        <div v-for="(item, index) in dataList" :key="index" class="alert" :style="{'background-image': `url(${require('@/assets/edu/420x174.png')})`}">
          <div class="a-header">
            <span style="font-size:19px">{{ item.companyName }}</span>
            <span>{{ item.createAt }}</span>
          </div>
          <div class="a-content">
            <div class="a-remark">
              <span>{{ item.canteenName }}</span>
              <span>{{ item.noticeContent }}</span>
            </div>
            <div class="a-img">
              <img v-if="item.imgUrl" :src="item.imgUrl" width="192px" height="108px">
            </div>
          </div>
        </div>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import { warningInfo } from '@/api/edu/edu'
export default {
  data() {
    return {
      classOption: {
        singleHeight: 174,
        limitMoveNum: 5
      },
      dataList: []
    }
  },
  methods: {
    init(school) {
      warningInfo(school).then(res => {
        this.dataList = res.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import './BaseStyle.scss';
.title {
  margin-bottom: 10px;
}
.alert {
  width: calc(96% - 40px);
  margin: 0px auto 20px auto;
  height: 134px;
  padding: 10px;
  background-size: 100% 100%;
  .a-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .a-content {
    height: 108px;
    display: flex;
    justify-content: space-between;
    .a-remark {
      height: 108px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .a-img {
      width: 192px;
      height: 108px;
    }
  }
}
.scroll {
  height: calc(76vh);
  overflow: hidden;
}
</style>
